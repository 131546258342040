<template>
  <div class="reset">
    <div class="content_top">
      <div class="content_title">{{ TableData.title }}</div>
      <div class="content_desc">
        {{ TableData.type }} | {{ TableData.pubDateStr }} |
        {{ TableData.postField }}
      </div>
    </div>
    <van-divider />
    <div style="padding: 0 20px">
      <div v-for="(item, index) in TableData.content" :key="index">
        {{ item }}
      </div>
    </div>
    <van-divider />
    <div class="content_footer">
      <div>
        <span class="content_enclosure">附件下载：</span>
        <span
          class="content_noenclosure"
          v-if="TableData.attachments.length == 0"
        >
          无附件
        </span>
      </div>
      <div v-if="TableData.attachments.length != 0">
        <div
          class="enclosure_item pointer-hover"
          v-for="(item, index) in TableData.attachments"
          :key="index"
          @click="downLoadAttachFun(item)"
        >
          <el-link class="pointer-hover">{{ item.name }}</el-link>
        </div>
      </div>
      <div align="center" v-if="needStudy">
        <div
          style="width: 200px; height: 50px"
          v-if="TableData.readStatus == '未学习'"
        >
          <van-button
            type="info"
            style="width: 100%; height: 100%"
            @click="studyClick"
            :disabled="!(this.times <= 0)"
            >确认学习
            <span v-if="this.times != 0">({{ this.time }})</span>
          </van-button>
        </div>
        <div
          style="width: 200px; height: 50px"
          v-if="TableData.readStatus == '已学习'"
        >
          <van-button type="info" :disabled="true">已学习 </van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Notify, Button, Field, Form, Divider } from "vant";
import { getdetailApi, getfinishstudyApi } from "@/api/rules/rules";
export default {
  name: "resetPassword",
  components: {
    [Divider.name]: Divider,
    [Notify.name]: Notify,
    [Button.name]: Button,
    [Field.name]: Field,
    [Form.name]: Form,
  },

  data() {
    return {
      isLoading: false,
      TableData: [],
      needStudy: true,
      times: 5,
      time: "",
    };
  },
  mounted() {
    this.getDetails();
    this.CountDown();
  },
  created() {},
  methods: {
    getDetails() {
      let ref = window.location.href;
      let id = 0;
      if (ref.indexOf("?id=") != -1) {
        id = Number(ref.substring(ref.indexOf("?id=") + 4));
        getdetailApi({ id: id }).then((res) => {
          this.TableData = res.data;
          this.TableData.content =
            this.TableData.content.split(/[(\r\n)\r\n]+/);
          this.times = res.data.studyTime;
          console.log("tab", this.TableData);
        });
      }
    },
    CountDown() {
      this.timer = setInterval(() => {
        this.times--;
        let m = Math.floor(this.times / 60);
        let s = this.times % 60;
        if (m == 0) {
          this.time = s + " 秒";
        } else {
          this.time = m + " 分 " + s + " 秒";
        }
        if (this.times === 0) {
          this.show = true;
          clearInterval(this.timer);
        }
      }, 1000);
    },
    studyClick() {
      if (!this.times) {
        console.log("2");
        let ref = window.location.href;
        let id = Number(ref.substring(ref.indexOf("?id=") + 4));
        getfinishstudyApi({ id: id }).then((res) => {
          if (res.code == 200) {
            Notify({
              type: "success",
              message: "学习完成",
            });
          }
          this.getDetails();
        });
      }
      console.log("1");
    },
    // 下载附件
    downLoadAttachFun(item) {
      // let name = item.name;
      let url = item.uri;
      // this.handleGetBlob(url)
      //   .then((blob) => {
      //     this.handleSaveAs(blob, name);
      //   })
      //   .catch((err) => {
      //     console.log("下载错误", err);
      //     this.$message.warning("下载错误");
      //   });
      var form = document.createElement('form');
      form.action = url;
      document.getElementsByTagName('body')[0].appendChild(form);
      window
      form.submit();
    },
    // handleGetBlob (url) {//全局函数1
    //     return new Promise(resolve => {
    //         const xhr = new XMLHttpRequest();
    //         xhr.open("GET", url, true);
    //         xhr.responseType = "blob";
    //         xhr.onload = () => {
    //             if (xhr.status === 200) {
    //                 resolve(xhr.response);
    //             }
    //         };
    //         xhr.send();
    //     });
    // },
    // //下载文件并重新命名
    // handleSaveAs (blob, filename) {//全局函数2
    //     if (window.navigator.msSaveOrOpenBlob) {
    //         navigator.msSaveBlob(blob, filename);
    //     } else {
    //         const link = document.createElement("a");
    //         const body = document.querySelector("body");
    //         link.href = window.URL.createObjectURL(blob);
    //         link.download = filename;
    //         // fix Firefox
    //         link.style.display = "none";
    //         body.appendChild(link);
    //         link.click();
    //         body.removeChild(link);
    //         window.URL.revokeObjectURL(link.href);
    //     }
    // }
    //   提交
    // onSubmit() {
    //   let ref = window.location.href;
    //   ref = Number(ref.substring(ref.indexOf("id=") + 3, ref.indexOf("#/")));
    //   getsignin({ ActivityID: ref }).then((res) => {
    //     if (res.code == 200) {
    //       Notify({
    //         type: "success",
    //         message: "签到成功",
    //       });
    //     }
    //     this.getDetails();
    //   });
    // },
  },
};
</script>
<style lang="scss" scoped>
.content_top {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  .content_title {
    margin-top: 10px;
    font-size: 16px;
    color: #333;
    padding-bottom: 20px;
  }
  .content_desc {
    font-size: 16px;
    color: gray;
  }
}

.content_footer {
  padding-bottom: 170px;
  padding-left: 20px;
  .content_footer_divider {
    margin-top: 20px;
    width: 100%;
  }
  .content_enclosure {
    font-size: 16px;
    font-weight: bold;
    color: red;
    margin-bottom: 10px;
  }
  .content_noenclosure {
    font-size: 12px;
    font-weight: bold;
    color: gray;
    margin-bottom: 10px;
  }
  .enclosure_item {
    color: rgb(114, 114, 114);
    font-size: 14px;
    margin-bottom: 4px;
  }
}
</style>