import request from '@/utils/request'

export function getPageApi(data) {
    return request({
        url: '/rules/Rules11/getpage',
        method: 'get',
        params:data
    })
}
export function getunstudypageApi(data) {
    return request({
        url: '/rules/Rules11/getunstudypage',
        method: 'get',
        params:data
    })
}
export function getstudiedpageApi(data) {
    return request({
        url: '/rules/Rules11/getstudiedpage',
        method: 'get',
        params:data
    })
}
export function getdetailApi(data) {
    return request({
        url: '/rules/Rules11/getdetail',
        method: 'get',
        params:data
    })
}
export function getfinishstudyApi(data) {
    return request({
        url: '/rules/Rules11/finishstudy',
        method: 'get',
        params:data
    })
}
